import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import FAQ from '../components/FAQ';

export default () => (
  <React.Fragment>
    <SEO
      title="FAQ | Mindset Family Therapy"
      description="Answers to your Mindset Family Therapy frequently asked questions."
      pathname="/frequently-asked-questions"
    />
    <Layout>
      <FAQ />
    </Layout>
  </React.Fragment>
);
